import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider} from '../_metronic/layout/core'
import {Routes} from './routing/Routes'
import {UserProvider} from './modules/context/UserProvider'
import {NotificationsProvider} from './modules/context/NotificationsProvider'


type Props = {
    basename: string
}

const App: React.FC<Props> = ({basename}) => {

    return (
        <BrowserRouter basename={basename}>
            <I18nProvider>
                <UserProvider>
                    <NotificationsProvider>
                        <LayoutProvider>
                            <Routes />
                        </LayoutProvider>
                    </NotificationsProvider>
                </UserProvider>
            </I18nProvider>
        </BrowserRouter>
)
}

export {
    App
}

/**
*  Deployment
*
*  @Server
*      URL: 146.59.152.130
*      USERNAME: root
*      PASSWORD: Ke569lZnaFpPkzN
*  @FTP
*      USERNAME: client.webforce.be
*      PASSWORD: 4kWs9S$mx5m^gFkx
*      package.json: "homepage": "https://client.webforce.be/",
*      env: REACT_APP_API_URL=https://mywebforce.webforce.be/api/
*
*
*  @PRE-PROD
*      URL: https://client.serverweb-prod.com/
*      USERNAME: mywebforce
*      PASSWORD: GlobalEtik@2023
*
*  @FTP
*      USERNAME: client.mywebforce-ftp
*      PASSWORD: hD_7u287q
*      package.json: "homepage": "https://client.serverweb-prod.com/",
*/
