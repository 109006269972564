export interface Item {
    created_at: string;
    type: string;
    name: string;
    slug: string;
    oldVersion: string;
    newVersion: string;
}

export interface WPData {
    wp: Item[];
    theme: Item[];
    plugin: Item[];
}

export const initialWPData: WPData = {
    wp: [],
    theme: [],
    plugin: []
};