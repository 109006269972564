import {useQuery} from 'react-query'
import {useState} from 'react'
import {Get} from '../../../modules/functions/get/Get'
import {InterfaceAnnouncement, InterfaceDataAnnouncement} from '../ts'
import {useUser} from '../../../modules/context/types'

const ANNOUNCEMENT = process.env.REACT_APP_DASHBOARD_ANNOUNCEMENT || 'v2/annonce-homepage'

export const Announcement = () => {

    const [data, setData] = useState<InterfaceAnnouncement | null>(null)
    const {personalInformation: {workspace}} = useUser()
    const primary = workspace?.color.main ? workspace.color.main : "#0094f3"
    const secondary = workspace?.color.second ? workspace.color.second : "#e1006d"

    useQuery(
        `dashboard_announcement`,
        () => Get<InterfaceDataAnnouncement>({endpoint: ANNOUNCEMENT}),
        {
            onSuccess: (data) => {

                if (data) {
                    setData(data.data.annonce_homepage_1)
                }

                //
            },
        },
    )

    const stripHtml = (html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };


    return (
        <div className="row my-5">
            <div className="col-12">
                {/*begin::Engage widget*/}
                <div className="card h-lg-100"
                     style={{background: `linear-gradient(112.14deg, ${primary} 0%, ${secondary} 100%)`}}
                >
                    {/*begin::body*/}
                    {
                        data
                            ? (
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-8 pe-0 mb-5 mb-sm-0">
                                            <div
                                                className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
                                                {/*begin::Container*/}
                                                <div className="mb-7">
                                                    <div className="mb-6">
                                                        <h3 className="fs-2x fw-semibold text-white">{data.title}</h3>
                                                        <span className="fw-semibold text-white opacity-75">{stripHtml(data.text)}</span>
                                                    </div>
                                                    {/*begin::Items*/}
                                                    <div className="d-flex align-items-center flex-wrap d-grid gap-2">

                                                        {
                                                            data.icon.map((item, index) => (
                                                                <div className="d-flex align-items-center me-5 me-xl-13"
                                                                     key={index}>
                                                                    <div className="symbol symbol-30px symbol-circle me-3">
                                                                        <div className="symbol-label"
                                                                             style={{background: 'rgba(255, 255, 255, 0.15)'}}
                                                                        >
                                                                            <img src={`${item.src}`}
                                                                                 className="w-20px"
                                                                                 alt="" />

                                                                            {/*<KTSVG*/}
                                                                            {/*    path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                                            {/*    className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="m-0">
                                                                        <a href="#"
                                                                           className="text-white opacity-75 fs-8">{item.title}</a>
                                                                        <span
                                                                            className="fw-bold text-white fs-7 d-block">{item.text}</span>
                                                                    </div>
                                                                </div>

                                                            ))
                                                        }
                                                    </div>
                                                    {/*end::Items*/}

                                                </div>
                                                {/*end::Container*/}
                                                {/*begin::Action*/}
                                                <div className="m-0">
                                                    <a href={'mailto:support@webforce.be'}
                                                       className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold"
                                                    >
                                                        En savoir plus
                                                    </a>
                                                </div>
                                                {/*end::Action*/}
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <img src={`${data.image}`}
                                                 alt=""
                                                 className="w-300px" />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )
                    }
                    {/*begin::body*/}
                </div>
                {/*end::Engage widget*/}
            </div>
        </div>
    )
}